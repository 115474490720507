import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo2.png";
import navIcon3 from '../assets/img/nav-icon3.svg';
import navIcon4 from '../assets/img/nav-icon4.png';
import navIcon5 from '../assets/img/nav-icon5.png';
import navIcon6 from '../assets/img/shoppingcart.png';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.tiktok.com/@hennimaalaa"><img src={navIcon4} alt="Icon" /></a>
              <a href="https://www.instagram.com/hennimaalaa/"><img src={navIcon3} alt="Icon" /></a>
              <a href="http://taidesivut.net/hennilaine/taidemyynti"><img src={navIcon6} alt="Icon" /></a>
              <a href="https://linktr.ee/hennilaine"><img src={navIcon5} alt="Icon" /></a>
            </div>
            <p>© Henni Laine 2023. Kaikki oikeudet pidätetään.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}