import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import TrackVisibility from 'react-on-screen';
import landingpage from "../assets/img/landingpage4.png";

export const Banner = () => {

  return (
    
    <section className="banner" id="etusivu">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "": ""}>
                <Tab.Container id="galleria" defaultActiveKey="first">
                <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                    <img src={landingpage} alt=""></img>
                      <p className="p1">Olen taiteilija ja yrittäjä Hämeenlinnasta.<br></br><br></br> Maalaan uniikkeja tauluja sekatekniikalla, pääosin akryylimaaleilla.
                          Ominta tyyliäni on persoonallisen värikkäät toteutukset ja rakastan erityisesti esittävää taidetta. Viime aikoina olen kokeillut siipiäni myös abstraktissa suuntauksessa.<br></br><br></br>
                          Inspiraatio taiteelleni tulee niin luonnosta, eläimistä kuin mielenmaisemista. Taide kulkee isossa roolissa elämässäni ja maalaaminen on minulle hyvin terapeuttista.<br></br><br></br>
                          Valmiiden teosten lisäksi teen mielelläni myös tilaustöitä. Ota yhteyttä, niin suunnitellaan yhdessä juuri sinun toiveidesi mukainen uniikki teos!</p>
                    </Nav.Item>
                    <Nav.Item>
                    <p className="p3">Toimituksesta: <br></br> <br></br>Teokset postitetaan Postin tai Matkahuollon kautta, aina edullisimman vaihtoehdon mukaisesti. Toimitus voidaan sopia myös henkilökohtaisesti Hämeenlinnan
                  alueella, jolloin postikuluja ei tule.</p>
                  <br></br><br></br>
                      </Nav.Item>
                      <Nav.Item>
                    <p className="p2">Yhteydenotto: <br></br><br></br>
                      Sähköposti: hennitaide@gmail.com<br></br><br></br>
                      Instagram: @hennimaalaa<br></br><br></br>Tai lähetä viesti lomakkeella sivun yläosasta<br></br><br></br>Y-tunnus 3339220-7</p>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="" className={isVisible ? "" : ""}>
                    <Tab.Pane eventKey="first">
                    </Tab.Pane>
                  </Tab.Content>
                  <Tab.Content id="" className={isVisible ? "" : ""}>
                    <Tab.Pane eventKey="second">
                    </Tab.Pane>
                  </Tab.Content>
                  <Tab.Content id="" className={isVisible ? "" : ""}>
                    <Tab.Pane eventKey="third">
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
