import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/logo2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import emailjs from '@emailjs/browser';

export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Lähetä');
  const [status] = useState({});


  function onFormUpdate(category, value) {
    setFormDetails({
      ...formDetails,
      [category]: value
    });
  }
  const sendEmail = async (e) => {
    e.preventDefault();   
    emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, formDetails, process.env.REACT_APP_PUBLIC_KEY)
    .then((result) => {
      console.log(result.text)
      setButtonText('Kiitos viestistä!');
  }, (error) => {
      console.log(error.text)
      setButtonText('Hups! Viestiä ei lähetetty, yritä myöhemmin uudelleen');
  });
};

  return (
    <section className="contact" id="contact">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "" : ""} src={contactImg} alt="Contact Me"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "" : ""}>
                <h1>LÄHETÄ VIESTI</h1>
                <p>Oletko kiinnostunut jostakin teoksesta?<br></br> Kirjoita viestiin teoksen nimi. Voit myös ehdottaa hintaa.<br></br><br></br>
                Jos haluaisit keskustella uniikin maalauksen hankkimisesta tilaustyönä, voit laittaa viestiä tällä lomakkeella tai sähköpostilla hennitaide@gmail.com
                <br></br><br></br>Vastaan kaikkiin yhteydenottoihin pikimmiten!</p>
                <form onSubmit={sendEmail}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" value={formDetails.firstName} placeholder="Etunimi*" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" value={formDetails.lastName} placeholder="Sukunimi*" onChange={(e) => onFormUpdate('lastName', e.target.value)}/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" value={formDetails.email} placeholder="Sähköposti*" onChange={(e) => onFormUpdate('email', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" value={formDetails.phone} placeholder="Puhelinnumero*" onChange={(e) => onFormUpdate('phone', e.target.value)}/>
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" value={formDetails.message} placeholder="Viesti*" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                      <button type="submit"><span>{buttonText}</span></button>
                    </Col>
                    {
                      status.message &&
                      <Col>
                        <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                      </Col>
                    }
                  </Row>
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
