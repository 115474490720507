import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/logo2.png';
import navIcon3 from '../assets/img/nav-icon3.svg';
import navIcon4 from '../assets/img/nav-icon4.png';
import navIcon5 from '../assets/img/nav-icon5.png';
import navIcon6 from '../assets/img/shoppingcart.png';
import {
  BrowserRouter as Router
} from "react-router-dom";

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(true);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/etusivu" className={activeLink === 'etusivu' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('etusivu')}>Etusivu</Nav.Link>
              <Nav.Link href="/galleria" className={activeLink === 'galleria' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('galleria')}>Galleria</Nav.Link>
              <Nav.Link href="/yhteydenotto" className={activeLink === 'yhteydenotto' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('yhteydenotto')}>Ota yhteyttä</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.tiktok.com/@hennimaalaa"><img src={navIcon4} alt="Tik Tok" /></a>
                <a href="https://www.instagram.com/hennimaalaa/"><img src={navIcon3} alt="Instagram" /></a>
                <a href="http://taidesivut.net/hennilaine/taidemyynti"><img src={navIcon6} alt="Taidemyynti" /></a>
                <a href="https://linktr.ee/hennilaine"><img src={navIcon5} alt="Linktree" /></a>
              </div>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}
