import "./App.css";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import { Banner } from "./components/Banner";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { NavBar } from "./components/NavBar";
import React from 'react';


function App() {
  return (
    <div className="App">
      <NavBar />
    <Router>
      <Routes>
        <Route path="/" element={<Banner />} />
        <Route path="/etusivu" element={<Banner />} />
        <Route path="/galleria" element={<Projects />} />
        <Route path="/yhteydenotto" element={<Contact />} />
      </Routes>
    </Router>
    <Footer />
    </div>
  )
}

export default App;