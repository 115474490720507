import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/available/muija3.jpg";
import projImg4 from "../assets/available/jolene.jpg";
import projImg5 from "../assets/available/ilves.jpg";
import projImg11 from "../assets/available/inthisriver.jpg";
import projImg13 from "../assets/available/castleinthesky.jpg";
import projImg14 from "../assets/sold/desolation.jpg";
import projImg15 from "../assets/sold/morpheus.jpg";
import projImg16 from "../assets/img/updated/Never Give Up.jpg";
import projImg17 from "../assets/img/updated/Trashpanda.jpg";
import projImg18 from "../assets/sold/hietakissa.jpg";
import projImg19 from "../assets/sold/pingu1.jpg";
import projImg20 from "../assets/img/updated/Cup-Cake-Penguin.jpg";
import projImg21 from "../assets/sold/huskybaby.jpg";
import projImg22 from "../assets/sold/kettu.jpg";
import projImg23 from "../assets/sold/thiscutietho.jpg";
import projImg24 from "../assets/sold/alwaysbeyousold.png";
import projImg25 from "../assets/sold/allthesingleladies.jpg";
import projImg26 from "../assets/sold/darknesstakesover.jpg";
import projImg27 from "../assets/sold/wheninromesold.jpg";
import projImg28 from "../assets/sold/tilaustyö1.jpg";
import projImg29 from "../assets/img/updated/Beautybull.jpg";
import projImg30 from "../assets/sold/tilaustyö3.jpg";
import projImg31 from "../assets/sold/huskybaby2.jpg";
import projImg32 from "../assets/available/acrylicgarden.jpg";
import projImg33 from "../assets/sold/kilpikonna.jpg";
import projImg34 from "../assets/sold/bunny.jpg";
import projImg35 from "../assets/available/acrylicfloral.jpg";
import projImg37 from "../assets/sold/matthew.jpg";
import projImg38 from "../assets/sold/catsforever.jpg";
import projImg39 from "../assets/available/acrylicroses.jpg";
import projImg40 from "../assets/tilaustyöt/chihuahua.jpg";
import projImg41 from "../assets/tilaustyöt/puudeli.jpg";
import projImg42 from "../assets/available/turtle1.jpg";
import projImg43 from "../assets/available/traditionalbunny.jpg";
import projImg44 from "../assets/img/rottweiler.jpg";
import projImg45 from "../assets/tilaustyöt/tilaustyö4.jpg";
import projImg47 from "../assets/available/koikarpit.jpg";
import projImg48 from "../assets/sold/ultrasadpepesold.jpg";
import projImg49 from "../assets/sold/orvokki.jpg";
import projImg50 from "../assets/sold/mansikki.jpg";
import projImg51 from "../assets/available/kuren.jpg";
import projImg52 from "../assets/img/updated/Kitsune.jpg";
import projImg53 from "../assets/available/tuulikki.jpg";
import projImg55 from "../assets/img/updated/Panther.jpg";
import projImg56 from "../assets/img/updated/Tiger.jpg";
import projImg57 from "../assets/img/updated/Whatgoesaroundcomesaround.jpg";
import projImg58 from "../assets/available/Turtle2.jpg";
import projImg59 from "../assets/available/Tukaani.jpg";
import projImg60 from "../assets/sold/catsforever2.jpeg";
import projImg61 from "../assets/tilaustyöt/tilaustyö5.jpeg";
import projImg62 from "../assets/tilaustyöt/tilaustyö6.jpeg";
import projImg63 from "../assets/tilaustyöt/tilaustyö7.jpeg";

import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [

    {
      title: "Turtle 2",
      description: "",
      description2: "Akryylimaalaus 40cm x 40cm canvaspohjalle",
      imgUrl: projImg58,
    },
    {
      title: "What goes around... comes around",
      description: "220€",
      description2: "Akryylimaalaus 40cm x 40cm canvaspohjalle",
      imgUrl: projImg57,
    },
    {
      title: "Panther",
      description: "120€",
      description2: "Akryylimaalaus A4 akvarellipaperille",
      imgUrl: projImg55,
    },
    {
      title: "Tuulikki",
      description: "75€",
      description2: "Akryylimaalaus 27cm x 35cm kankaalle",
      imgUrl: projImg53,
    },
    {
      title: "Kurén",
      description: "170€",
      description2: "Akryylimaalaus 30cm x 30cm kankaalle",
      imgUrl: projImg51,
    },
    {
      title: "Koikarpit",
      description: "220€",
      description2: "Akryylimaalaus 33cm x 41cm",
      imgUrl: projImg47,
    },
    {
      title: "Turtle",
      description: "80€",
      description2: "Akryylimaalaus, 27cm x 35cm canvaspohjalle",
      imgUrl: projImg42,
    },
    {
      title: "Traditional bunny",
      description: "80€",
      description2: "Akryylimaalaus, 33cm x 41cm canvaspohjalle",
      imgUrl: projImg43,
    },
    {
      title: "Acrylic roses",
      description: "75€",
      description2: "Akryylimaalaus, 27cm x 35cm",
      imgUrl: projImg39,
    },
    {
      title: "Beautybull",
      description: "170€",
      description2: "Akryylimaalit & -tussit, 33cm x 41cm",
      imgUrl: projImg29,
    },
    {
      title: "Acrylic floral",
      description: "150€",
      description2: "Akryylimaalaus, 30cm x 30cm x 2cm",
      imgUrl: projImg35,
    },
    {
      title: "Acrylic garden",
      description: "150€",
      description2: "Akryylimaalit & -tussit, 30cm x 30cm x 2cm",
      imgUrl: projImg32,
    },
    {
      title: "Muija 3",
      description: "70€",
      description2: "Akryylimaalaus, 33cm x 41cm",
      imgUrl: projImg1,
    },
    {
      title: "Ilves",
      description: "50€",
      description2: "Akryyli- ja vesivärit, 33cm x 41cm",
      imgUrl: projImg5,
    },
    {
      title: "Jolene",
      description: "50€",
      description2: "Akryylimaalaus, 22cm x 27cm",
      imgUrl: projImg4,
    },
    {
      title: "Castle in the Sky",
      description: "220€",
      description2: "Akryylimaalaus, 50cm x 50cm canvaspohjalle",
      imgUrl: projImg13,
    },
  ];

  const projects2 = [

    {
      title: "Kitsune",
      description: "MYYTY",
      description2: "Akryylimaalaus 27cm x 35cm kankaalle",
      imgUrl: projImg52,
    },
    {
      title: "In this river...",
      description: "MYYTY",
      description2: "Akryylimaalaus, 50cm x 50cm canvaspohjalle",
      imgUrl: projImg11,
    },
    {
      title: "Tukaani",
      description: "MYYTY",
      description2: "Akryylimaalaus 33 cm x 41cm canvaspohjalle",
      imgUrl: projImg59,
    },
    {
      title: "Cats forever 2",
      description: "MYYTY",
      imgUrl: projImg60,
    },
    {
      title: "Cats forever",
      description: "MYYTY",
      description2: "Akryylimaalaus, 22cm x 27cm kangaspahvipohjalle",
      imgUrl: projImg38,
    },
    {
      title: "Tiger",
      description: "MYYTY",
      description2: "Akryylimaalaus A4 akvarellipaperille",
      imgUrl: projImg56,
    },
    {
      title: "Mansikki",
      description: "MYYTY",
      imgUrl: projImg50,
    },
    {
      title: "Orvokki",
      description: "MYYTY",
      imgUrl: projImg49,
    },
    {
      title: "Ultra Sad Pepe",
      description: "MYYTY",
      imgUrl: projImg48,
    },
    {
      title: "Bunny",
      description: "MYYTY",
      description2: "Akryylimaalaus 30cm x 30cm kiilakehyspohjalle",
      imgUrl: projImg34,
    },
    {
      title: "Desolation",
      description: "MYYTY",
      description2: "Akryylimaalaus, 35cm x 27cm",
      imgUrl: projImg14,
  },
  {
      title: "Morpheus",
      description: "MYYTY",
      description2: "Akryylimaalaus, 41cm x 33cm",
      imgUrl: projImg15,
  },
  {
      title: "Never Give Up",
      description: "MYYTY",
      description2: "Akryylimaalaus, 33cm x 41cm",
      imgUrl: projImg16,
  },
  {
      title: "Trashpanda",
      description: "MYYTY",
      description2: "Akryylimaalaus 33cm x 41cm",
      imgUrl: projImg17,
  },
  {
      title: "Hietakissa",
      description: "MYYTY",
      description2: "Akryylimaalaus, 27cm x 35cm",
      imgUrl: projImg18,
  },
  {
      title: "Ice Cream Penguin",
      description: "MYYTY",
      description2: "33cm x 41cm x 2cm",
      imgUrl: projImg19,
  },
  {
      title: "Cup Cake Penguin",
      description: "MYYTY",
      description2: "33cm x 41cm x 2cm",
      imgUrl: projImg20,
  },
  {
    title: "Huskybaby",
    description: "MYYTY",
    description2: "Akryylimaalaus, 33cm x 41cm",
    imgUrl: projImg21,
  },
  {
    title: "Huskybaby 2",
    description: "MYYTY",
    description2: "Akryylimaalit, -tussit & -musteet 27cm x 35cm",
    imgUrl: projImg31,
  }, 
  {
    title: "Kettu",
    description: "MYYTY",
    imgUrl: projImg22,
  },
  {
    title: "Always Be You",
    description: "MYYTY",
    description2: "Akryylimaalaus, 30cm x 30cm",
    imgUrl: projImg24,
  },
  {
    title: "All the single ladies",
    description: "MYYTY",
    description2: "Akryylimaalaus, 40cm x 40cm",
    imgUrl: projImg25,
  },
  {
    title: "Darkness takes over",
    description: "MYYTY",
    description2: "Akryylimaalaus, 50cm x 50cm",
    imgUrl: projImg26,
  },
  {
    title: "When in Rome",
    description: "MYYTY",
    description2: "Akryylimaalaus, 50cm x 50cm",
    imgUrl: projImg27,
  },
  {
    title: "Kilpikonna",
    description: "MYYTY",
    description2: "Akryylimaalaus, 41cm x 33cm",
    imgUrl: projImg33,
  },
  {
    title: "Matthew",
    description: "MYYTY",
    description2: "Akryylimaalaus, 41cm x 33cm",
    imgUrl: projImg37,
  },
  ];

  const projects3 = [

    {
      title: "Tilaustyö",
      description: "Akryylimaalaus 40cm x 50cm kankaalle",
      imgUrl: projImg63,
    },
    {
      title: "Tilaustyö",
      description: "",
      imgUrl: projImg62,
    },
    {
      title: "Tilaustyö",
      description: "",
      imgUrl: projImg61,
    },
    {
      title: "Tilaustyö",
      description: "Sekatekniikka 33cm x 41cm kiilakehyspohjalle",
      imgUrl: projImg45,
    },
    {
      title: "Tilaustyö",
      description: "Akryylimaalaus, 33cm x 41cm",
      imgUrl: projImg44,
    },
    {
      title: "Tilaustyö",
      description: "Akryylimaalaus, 27cm x 35cm",
      imgUrl: projImg28,
    },
    {
      title: "Tilaustyö",
      description: "Akryylimaalaus, 33cm x 41cm",
      imgUrl: projImg30,
    },
    {
      title: "Tilaustyö",
      description2: "Akryylimaalaus, 33cm x 41cm",
      imgUrl: projImg40,
    },
    {
      title: "Tilaustyö",
      description2: "Akryylimaalaus, 33cm x 41cm",
      imgUrl: projImg41,
    },
    {
      title: "Tilaustyö",
      description2: "Akryylimaalaus, 27cm x 22cm",
      imgUrl: projImg23,
    },
    ];

  return (
    <section className="project" id="galleria">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "": ""}>
                <h1>Lisätietoja napauttamalla kuvaa
                </h1>
                <Tab.Container id="galleria" defaultActiveKey="first">
                <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Vapaat teokset</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Myydyt teokset</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tilaustyöt</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="" className={isVisible ? "" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                  <Tab.Content id="" className={isVisible ? "" : ""}>
                    <Tab.Pane eventKey="second">
                      <Row>
                      {
                          projects2.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        } 
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                  <Tab.Content id="" className={isVisible ? "" : ""}>
                    <Tab.Pane eventKey="third">
                      <Row>
                        {
                          projects3.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
